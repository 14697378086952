
import { defineComponent } from 'vue';
import Home from './Home.vue';

export default defineComponent({
	name: 'App',
	components: {
		Home,
	},
});
